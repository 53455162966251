import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import Testimony from '../Home_v2/components/testimony'
import ContactUs from '../Home_v2/components/contactus';
import Landing from './components/landing'
import Categories from './components/Categories'
import MeetMentor from './components/mentor';
import ParticlesBg from 'particles-bg';

class Mentorship extends Component{
    render() {
        return (
            <div className = "wrapper">
                <section className='one'>
                    <Landing />
                </section>
                <section className="two">
                    <MeetMentor />
                </section>
                <section className="three">
                    <Categories></Categories>
                </section>
                <section className='four'>
                    <Testimony />
                </section>
                <section className='five'>
                    <ContactUs />
                </section>
            </div>
        );
    }
}


export default Mentorship;