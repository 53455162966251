import React, { Component } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import YoutubeEmbed from './YouTubeEmbed';
import '../src/css/mentor.css'


class MeetMentor extends Component {
    render() {
        return (
            <div style={{background:'#8ecae6'}}>
                <Container style={{ textAlign:'center',paddingBottom:'0%',fontSize:'18px',opacity:'1'}}>
                    <Row style={{ paddingTop: '5%' }}>
                        <Col>
                            <h1 style={{fontSize:'min(7vw,4rem)',color:'#FFFFFF',fontWeight:'900'}}>UniArk Mentorship</h1>
                        </Col>
                    </Row>
                    <center>
                    <Card style={{textAlign:'left', width:'100%',padding:'3%',background:'#050A30',color:'#000',border:'0px', borderRadius:'25px'}}>
                            <YoutubeEmbed />
                    </Card>
                    </center>
                </Container>
            </div>
        );
    }
}

export default MeetMentor;