import React, { Component } from "react";
import { ReactDOM } from "react";
import '../src/css/card-item.css'


class CardItem extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className='service-card' style={{ width: '100%', height:'10em',marginLeft:'auto',marginRight:'auto',marginTop:'5%',border:'0px',borderRadius:'7px',backgroundImage:'url('+this.props.src+')'}}>
                <div className="card-content">
                    <h4 style={{fontWeight:'bold',fontSize:'28px',color:'white',width:'75%',marginLeft:'auto',marginRight:'auto'}}>{this.props.title}</h4>
                    <p style={{textAlign:'justify', width:'75%',marginLeft:'auto',marginRight:'auto',fontSize:'18px',color:'white'}}>{this.props.content}</p>
                </div>
            </div>
        )
    }
}

export default CardItem;