import React from "react";
import { ReactDOM } from "react";   
import {Col, Row, Container, Card, Placeholder, Image} from "react-bootstrap";


import '../src/css/whydonate.css'


class WhyDonate extends React.Component {
    render(){
        return (
            <div className='donate-div' style={{paddingTop:'5%', backgroundColor:'#8ecae6'}}>
            <Container>
            <Card style={{textAlign:'left', width:'100%',paddingLeft:'3%',background:'#fff',color:'#000',border:'0px', borderRadius:'25px',overflow:'hidden'}}>
                <div className="donate-card-content">
                    <div className="donate-content" style={{padding:'2rem 2rem'}}>
                        <h1 style={{color: '#fb8500', fontWeight:'900', fontSize: 'min(2.5rem,7vw)'}}>Why Donate?</h1>
                        <p style={{textAlign:'justify',fontSize:'min(1.1rem,4vw)'}}>
                        Our charity is run entirely by volunteers, some of whom contribute financially towards our operating costs from their own pockets. 
                        However, extra funding is neccessary for us to run more efficiently, such as to pay for access to premium Zoom with advanced features and uninterrupted meetings for our mentors and students. 
                        Therefore, we are seeking support to help us run smoothly, effectively and efficiently.
                        </p>
                        <a href="https://www.paypal.com/donate?hosted_button_id=NCP9Y3H9C6J8U" target="_blank">
                        <button style={{fontSize: 'min(1.5rem,4vw)', borderRadius: '25px', border: '0', color:'white', background:'#fb8500', paddingInline:'15px', fontWeight:'bold', paddingTop:'0.5rem', paddingBottom:'0.5rem', wordWrap: 'break-word'}}
                        > Donate Here </button>
                        </a>
                    </div>
                    <div className='right-card'>&nbsp;</div>
                </div>
            </Card>
            </Container>
        </div>
        )   
    }

}

export default WhyDonate;