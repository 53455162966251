import React, { Component } from "react";
import { ReactDOM } from "react";
import {Col, Row, Container, Card} from "react-bootstrap";
import '../src/css/story.css'

class Story extends Component{

    render(){
        return (
            <div style={{backgroundColor: "#8ecae6"}}>
                <Container className="story-div">
                    <Card className="story-card">
                        <div className="story-card-content">
                            <div className='left-card2'>&nbsp;</div>
                            <div className="story-content" style={{padding:'2rem 2rem'}}>
                            <h1 className="story-title" style={{color: 'white',fontWeight:'900'}}>Our Story</h1>
                                <p style={{textAlign:'justify',fontSize:'16px', color:'white'}}>
                                At UniArk, many of us have firsthand experiences of persecution and disadvantages due to our ethnic, gender, and religious identities. Coming from communities with limited opportunities, we have united to improve access to higher education for individuals from similar backgrounds.
                                <br></br> <br></br>
                                Our mission was particularly inspired by the story of Shamsia Alizada, a young woman from the Hazara minority ethnic group. Despite narrowly escaping an attack on her educational center, Shamsia topped Afghanistan's university entrance exams two years later. At UniArk, we are dedicated to helping students like Shamsia reach their full potential and transform their lives through education.
                                </p>
                            </div>
                        </div>
                    </Card>
                </Container>
            </div>
        )
    }
}

export default Story;