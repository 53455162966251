import React from "react";
import Landing from './components/landing'
import Terms from "./components/terms";
import ContactUs from "../Home_v2/components/contactus";

class ApplyMentor extends React.Component {
  render() {
    return (
      <div className="policy">
        <section className="one">
            <Landing />
        </section>
        <section className="two">
            <Terms />
        </section>
        <section className='three'>
            <ContactUs />
        </section>
      </div>
    );
  }
}

export default ApplyMentor;