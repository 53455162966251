import React from 'react';
import '../src/css/university-logos.css';

const UniversityLogos = () => {
  const logos = [
    'Cambridge2.png',
    'stanford.png',
    'Oxford.png',
    'geneva.png',
    'Sussex.png',
    'MountHolyoke2.png',
    'UniversityCollegeDublin.png'
  ];

  return (
    <div className="UniversityLogos" style={{ background:'#8ecae6', paddingTop:'5%' }}>
      <h1 style={{ fontSize:'min(4rem,7vw)', color:'#fff', fontWeight:'900' }}>Our Student Achievements</h1>
      <p style={{ fontSize: 'max(1.05rem,1.5vw)', color: '#fff', marginBottom: '20px' }}>The universities we have sent students to</p>
      <div className="university-logos-container">
        {logos.map((logo, index) => (
          <img
            key={index}
            src={process.env.PUBLIC_URL + `/UniversityLogos/${logo}`}
            alt={`University Logo ${index}`}
            className="university-logo"
          />
        ))}
      </div>
    </div>
  );
};

export default UniversityLogos;
