import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import Card from './cards';
import '../src/css/leaders.css'
import members from '../data/members.json'

class Leaders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div style={{background:'#8ecae6', paddingTop:'7%', paddingBottom:'3rem'}}>
            <Container className='leaders-section'>
                <h1 className='leaders-title' style={{textAlign:'center', fontWeight:'bold', color:'white', fontSize:'min(7vw,4rem)'}}>Administration Team</h1>
                <div className='leaders-container' >
                    {
                        members.leaders.map((member) => (
                            <Card fname={member.first_name} lname={member.last_name} src={member.img} position={member.position} uni={member.education[0].uni} degree={member.education[0].degree} cardTitle={'#fb8500'}/>
                        )
                        
                        )
                    }
                </div>
            </Container>
            </div>
        );
    }
}

Leaders.propTypes = {};

export default Leaders;
