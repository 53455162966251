import React,{useState} from "react";
import { ReactDOM } from "react";
import {Col, Row, Container, Card,Button,Modal} from "react-bootstrap";
import '../src/css/howhelp.css'


function HowHelp()  {
    const [show1, setShow1] = useState(false);
    const [show2,setShow2] = useState(false);
    const [show3,setShow3] = useState(false);
    

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    return (
        <div className='donate-div' style={{paddingTop:'5%',paddingBottom:'5%', backgroundColor:'#8ecae6'}}>
            <Container>
                    <h1 style={{color: 'white', fontWeight:'900', fontSize: 'min(7vw,4rem)',textAlign:'center'}}>How Else Can You Help?</h1>
                <div className="howdonate-cards">
                    <Card className='howdonate-card' onClick={handleShow1}>
                        <Card.Img variant="top" src={require('../src/img/sponsor.jpg')} style={{borderRadius:'25px 25px 0px 0px'}} />
                        <Card.Body style={{background:'#050A30'}}>
                            <Card.Title style={{textAlign:'center'}}><h4 style={{color:'white'}}>Sponsor a Student</h4></Card.Title>
                        </Card.Body>
                    </Card>
                    <Card className='howdonate-card' onClick={handleShow2}>
                        <Card.Img variant="top" src={require('../src/img/s2.jpeg')} style={{borderRadius:'25px 25px 0px 0px'}}/>
                        <Card.Body style={{background:'#050A30'}}>
                            <Card.Title style={{textAlign:'center'}}><h4 style={{color:'white'}}>Volunteer or Mentor</h4></Card.Title>
                        </Card.Body>
                    </Card>
                    <Card className='howdonate-card' onClick={handleShow3}>
                        <Card.Img variant="top" src={require('../src/img/s3.jpg')} style={{borderRadius:'25px 25px 0px 0px'}}/>
                        <Card.Body style={{background:'#050A30'}}>
                            <Card.Title style={{textAlign:'center'}}><h4 style={{color:'white'}}>Join our Board of Advisors</h4></Card.Title>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        
            <Modal show={show1} onHide={handleClose1} size="xl" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header closeButton closeVariant="white">
                <Modal.Title><h2 style={{color:'white'}}>Sponsor a Student</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{paddingTop:'3%',paddingBottom:'3%',display:'flex',alignItems:'center'}}>
                        <div className="howdonate-modal-image" style={{backgroundImage:'url('+ require('../src/img/s1-sq.jpeg')+')'}}>
                        </div>
                        <div className="howdonate-modal-content" style={{padding:'3%',textAlign:'justify',fontSize:'18px'}}>
                        There are very limited fully funded scholarships for international students in the UK which are highly competitive. 
                        Though country and course specific scholarships exist, not all students are always eligible such as those from Afghanistan and those who wish to study non-STEM subjects. 
                        Therefore, we are seeking sponsors who are willing to sponsor a student to take up their admission offers in the UK and US. Depending on how much funding the student has already been offered by the institution, the sponsor will cover the remaining costs for the duration of the course. The sponsor will be able to discuss the conditions, the amount and the process with UniArk before committing to sponsoring a student. 
                        By sponsoring a student, you will be changing a young life and giving them the opportunity to leave their oppressive environment and seek the light of education. Get in touch with us and make the gift of education now. 
                        </div>
                    </div>
                    <div style={{textAlign: 'right', paddingRight:'5%', paddingBottom:'2%'}}>
                        <Button variant="primary" style={{borderRadius: '25px', background:'#fb8500',borderColor:'white',width:'15%',minWidth:'150px',fontSize:'18px'}}>
                        Email Us
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={show2} onHide={handleClose2} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton closeVariant="white">
                <Modal.Title><h2 style={{color:'white'}}>Become a volunteer or mentor</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{paddingTop:'3%',paddingBottom:'3%',display:'flex',alignItems:'center'}}>  
                        <div className="howdonate-modal-image" style={{backgroundImage:'url('+ require('../src/img/s2-sq.jpeg')+')'}}>
                        </div>
                        <div className="howdonate-modal-content" style={{padding:'3%',textAlign:'justify',fontSize:'18px'}}>
                        To help more students, we need more mentors who are passionate about making a difference in society by helping students from disadvantaged backgrounds. We are looking for mentors of all academic backgrounds to help us in empowering students from ethnic minorities and financially disadvantaged backgrounds. Mentor as often or as little as you want! Click here to read the mentor information pack and learn more about the mentoring programme.
                        We are also hiring for a range of other roles to help us run the charity. Please get in touch with us through email to discuss how you can get involved.
                        </div>
                    </div>
                    <div style={{textAlign: 'right', paddingRight:'5%', paddingBottom:'2%'}}>
                        <a href='/#/ApplyMentor'>
                        <Button variant="primary" style={{color:'white', borderRadius: '25px', background:'#fb8500',borderColor:'white',width:'15%',minWidth:'200px',fontSize:'18px'}}>
                        Apply Here
                        </Button>
                        </a>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={show3} onHide={handleClose3} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton closeVariant="white">
                <Modal.Title><h2 style={{color:'white'}}>Join our Board of Advisors</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{paddingTop:'3%',paddingBottom:'3%',display:'flex',alignItems:'center'}}>  
                        <div className="howdonate-modal-image" style={{backgroundImage:'url('+ require('../src/img/s3-sq.jpg')+')'}}>
                        </div>
                        <div className="howdonate-modal-content" style={{padding:'3%',textAlign:'justify',fontSize:'18px'}}>
                        Do you have experience in third-sector management? 
                        Join our board of advisors to help UniArk scale up, steer its wheel towards progress and support its education campaign. 
                        Click here to find out more and email us your interest.
                        </div>
                    </div>
                    <div style={{textAlign: 'right', paddingRight:'5%', paddingBottom:'2%'}}>
                        <Button variant="primary" style={{borderRadius: '25px', background:'#fb8500',borderColor:'white',width:'15%',minWidth:'200px',fontSize:'18px'}}>
                        Email Us
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

// render(<HowHelp />);

export default HowHelp;