import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
// import styles from '../src/css/Home.css'
// import UniNav from './Navbar'
// import Option from './Option'
import '../src/css/option.css'
import { Container, Row, Col,Button } from 'react-bootstrap';
class Option extends Component {

    render(){
        return (
            <Container fluid className='main-container-AU' style={{fontFamily:'Avenir',paddingBottom:'5%'}}>
                <h1 style={{color:'white',textAlign:'center',fontSize:'min(7rem,15vw)'}}>Application Form</h1>
            </Container>
        );
    }
}

export default Option;