import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
// import styles from '../src/css/Home.css'
import UniNav from '../../Home_v2/components/Navbar'
// import Option from './Option'
import '../src/css/option.css'
import { Container, Row, Col,Button } from 'react-bootstrap';
class Option extends Component {

    render(){
        return (
            <Container fluid className='main-container-AU' style={{fontFamily:'Avenir',paddingBottom:'5%'}}>
                <h1 style={{color:'white',textAlign:'center',fontSize:'min(15vw,6rem)',letterSpacing:"0px",position:'relative',left:'-0.4%'}}>Meet The Team</h1>
            </Container>
        );
    }
}

export default Option;