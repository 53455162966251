import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import { Row, Col, Container } from 'react-bootstrap';
import '../../Home_v2/src/css/youtubeembed.css'

class YoutubeEmbed extends Component {
    render() {
        return (
            <div className="video-responsive">
                <iframe
                    className='youtube-embed'
                    src="https://www.youtube.com/embed/PQQoW8Tgc-E?si=U3OQza8dKLxqAkxa"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    frameborder="0"
                    allowfullscreen
                />
            </div>
        );
    }
}

export default YoutubeEmbed;
