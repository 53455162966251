import React from 'react';
import PropTypes from 'prop-types';
import Landing from './components/landing';
import Leaders from './components/leaders';
import ScrollCards from './components/scrollcards';
import members from './data/members.json';
import ScrollCardsV2 from './components/scrollcardv2';
import ContactUs from '../Home_v2/components/contactus';

class Team extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className='team'>
                <section className='one'>
                    <Landing />
                </section>
                <section className='two'>
                    <Leaders />
                </section>
                <section className='three'>
                    <ScrollCards title='Advisory Board' division={members.advisors} cardColor='#050A30' cardText='white' cardTitle='white'/>
                </section>
                <section className='four'>
                    <ScrollCardsV2 title='UK Admissions Team' division={members.ukTeam} cardColor='white' cardText='black' cardTitle='#fb8500'/>
                </section>
                <section className='five'>
                    <ScrollCardsV2 title='US Admissions Team' division={members.usTeam} cardColor='#050A30' cardText='white' cardTitle='white'/>
                </section>
                <section className='six'>
                    <ScrollCardsV2 title='Tech Support' division={members.techSupport} cardColor='white' cardText='black' cardTitle='#fb8500'/>
                </section>
                <section className='seven'>
                    <ContactUs />
                </section>
            </div>
        );
    }
}

Team.propTypes = {};

export default Team;
