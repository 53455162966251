import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Container } from 'react-bootstrap';
import AcItem from './accordion';
import '../src/css/terms.css';

class Terms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className='terms' style={{background:'#8ecae6'}}>
                <Container>
                    <Accordion>
                        <Accordion.Item className='accordion-item' eventKey='0'>
                            <Accordion.Header> <h3 className="accordion-title"> <b style={{color:'#fb8500'}}>1.</b> Will I be guaranteed university admission/scholarship if mentored by UniArk?</h3> </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                            No one is guaranteed an offer before making the application. Your applications to the universities will be assessed by the universities alone and offers will be made based on your academic abilities and achievements. The UniArk mentorship programme can only support you in preparing a competitive application which can increase your chances of securing offers.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='accordion-item' eventKey='1'>
                            <Accordion.Header> <h3 className="accordion-title"> <b style={{color:'#fb8500'}}>2.</b> Does UniArk offer financial support, bursary, funding or scholarships?</h3> </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                            Currently, UniArk is not in a position to offer financial support to students. However, if/when there are donors who are prepared to sponsor students, we will get in touch with the student directly to offer this support. 
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='accordion-item' eventKey='2'>
                            <Accordion.Header> <h3 className="accordion-title"> <b style={{color:'#fb8500'}}>3.</b> Does UniArk offer exchange programmes?</h3> </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                            No, UniArk is a mentoring programme only and it does not involve any university or school exchange programmes.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='accordion-item' eventKey='3'>
                            <Accordion.Header> <h3 className="accordion-title"> <b style={{color:'#fb8500'}}>4.</b> How much support with my application will I receive from my mentor?</h3> </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                            You must treat your mentor as a guide only. He/she cannot and is not expected to do your work for you. You take the initiative to get started on your application, write your personal statement, prepare your essays and then show them to your mentor so that you receive constructive feedback on how you can improve them. Our mentors will only be able to help you if you take a proactive role in preparing your application yourself. 
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='accordion-item' eventKey='4'>
                            <Accordion.Header> <h3 className="accordion-title"> <b style={{color:'#fb8500'}}>5.</b> How many universities can I apply to?</h3> </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                            You are encouraged to apply to as many universities as there are eligible scholarships for you. However, this must be communicated with your mentor well in advance so that any deadlines are worked towards and applications are prepared in a timely manner. 
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='accordion-item' eventKey='5'>
                            <Accordion.Header> <h3 className="accordion-title"> <b style={{color:'#fb8500'}}>6.</b> How many subject courses can I apply for?</h3> </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                            You are not encouraged to apply to more than one area of study/course. It will not reflect well on your application if you apply to more than one programme from different fields. E.g. Psychology and Maths/Economics. As these fields are entirely different, they require different skills and abilities. However, it is okay to apply for variations of the same course. For example, you can apply to study Politics in one university and International Relations in another. The differences between the two are not significant and preparing separate applications will not be time consuming.                             </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='accordion-item' eventKey='6'>
                            <Accordion.Header> <h3 className="accordion-title"> <b style={{color:'#fb8500'}}>7.</b> What if I do not find the mentoring helpful?</h3> </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                            Our mentors are current students and graduates from elite global institutions. We believe they are qualified to help you make a competitive application. Must you have reasons to believe the mentoring you are receiving is inadequate, please get in touch with us to let us know what is not working for you and what you wish for your mentor to help you with. We can only know and help you, if you let us know.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='accordion-item' eventKey='7'>
                            <Accordion.Header> <h3 className="accordion-title"> <b style={{color:'#fb8500'}}>8.</b> What if my mentor is not my match?</h3> </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                            Our matching process follows a strict procedure and is given careful consideration. However, if you have a reasonable explanation as to why you think your match is not appropriate then please let us know and we are happy to reconsider and where possible assign another mentor.
                            </Accordion.Body>
                       </Accordion.Item>     
                       <Accordion.Item className='accordion-item' eventKey='8'>
                            <Accordion.Header> <h3 className="accordion-title"> <b style={{color:'#fb8500'}}>9.</b> If I am successful, will UniArk help me with my student visa application?</h3> </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                            Yes, UniArk will offer support and guidance with your student visa application. However, please do not equate this to legal support or legal representation at visa centres/embassies. Our mentors will offer as much support as possible to help you make sense of the visa application process. As each country has its own specific rules and requirements, you are advised to do your own research and only request support if/when you need it. Your student visa application is entirely your own responsibility and we will not be held liable for any unsuccessful applications.                            </Accordion.Body>
                       </Accordion.Item>   
                    </Accordion>
                </Container>
                
            </div>
        );
    }
}

Terms.propTypes = {};

export default Terms;
