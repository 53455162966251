import React,{useState} from "react";
import {Col, Row, Container, Card,Button,Modal} from "react-bootstrap";
import '../src/css/documents.css'

function Documents()  {
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);
    const cardstyle={
        width:'100%',
        borderRadius: '25px',
        border: '0px'
    }
    const imageStyle = {
        maxWidth: '100%', /* Ensure image doesn't exceed container width */
        height: 'auto', /* Maintain aspect ratio */
    };

    return (
        <div className='donate-div' style={{paddingTop:'5%', background:'#8ecae6'}}>
            
            {/* <h1 style={{fontWeight: 'bold',fontSize:'min(7vw,4rem)',color:'#fff', textAlign:'center'}}>Overview</h1> */}
            <Container>
                <Card style={cardstyle}>
                    <Card.Body>
                    {/* <img src={process.env.PUBLIC_URL + `/UWS/${'UWSmainImage.PNG'}`} alt="UWS image main" style={imageStyle} /> */}
                        <Card.Body>
                            <h3 style={{fontWeight:'bold', color:'#fb8500'}}>MANDATORY DOCUMENTS</h3>
                            <p>Applications missing <strong>any</strong> of the following documents will <strong>not</strong> be considered:</p>
                            <ol className="numbered-list">
                                <li><span className="underline">CV </span> </li>
                                <li><span className = "underline"> Identification: </span>Copy of passport or other identification document such as Tazkira </li>
                                <li><span className="underline">Proof of residence:</span>  (i.e bank statement; employment letter; school/university letter; reference letter)</li>
                                <li><span className="underline">Unviersity Offer</span>  Offer letter from the university </li>
                                <li><span className="underline">Financial Requirement:</span> Document from the university outlining financial requirements to be satisfied (if not already mentioned in offer letter) </li>
                                <li><span className="underline">Financial Support:</span> Proof of all other financial support obtained by the applicant (including personal funds) for the purpose of enrolling their degree</li>
                            </ol>
                        </Card.Body>
                    </Card.Body>
                </Card>
            </Container>
        </div>

    );
}

// render(<HowHelp />);

export default Documents;