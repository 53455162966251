import React, {Component} from "react";
import { ReactDOM } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import '../src/css/contactus.css'

class ContactUs extends Component{
    render(){
        return (
            <div className="contactus" style={{paddingTop:'3%',background:'#FFF'}}>
                <Container style={{paddingTop:'3%'}}>
                    <Card style={{height:'18em',border:'0px'}}>                        
                        <Card.Body style={{paddingLeft:'3%',fontSize:'18px'}}>
                        UniArk CIO is a registered charity in England and Wales (Charity Reg. Number: 1199070)
                        <Row style={{paddingTop:'3%'}}>
                            <Col style={{paddingBlock:'2%'}}>
                                <h5 style={{fontWeight:'bold'}}>For General Enquiries</h5>
                                admin@uniark.co.uk
                            </Col>
                            <Col style={{paddingBlock:'2%'}}>
                                <h5 style={{fontWeight:'bold'}}>Contact a Trustee</h5>
                                nsharifi650@gmail.com
                            </Col>
                        </Row>
                        <Row style={{paddingTop:'3%'}}>
                            <Col>
                                <h5 style={{fontWeight:'bold'}} href><a href="/#/Policy">Policies and Statements</a></h5>
                            </Col>
                            <Col>
                            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
                            <a href="https://www.facebook.com/uniarkfoundation" class="fa fa-facebook" style={{paddingRight: "5px"}}></a>
                            <a href="https://twitter.com/uni_ark" class="fa fa-twitter" style={{paddingLeft:"5px", paddingRight: "5px"}}></a>
                            <a href="https://www.instagram.com/uniarkfoundation" class="fa fa-instagram" style={{paddingLeft:"5px", paddingRight: "5px"}}></a>
                            <a href="https://www.linkedin.com/company/uni-ark/" class="fa fa-linkedin" style={{paddingLeft:"5px", paddingRight: "5px"}}></a>
                            </Col>
                        </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        );
    }
}

export default ContactUs;