import React, {Component} from 'react';
import Landing from './components/Landing'
import ContactUs from '../Home_v2/components/contactus';
import Newsitems from './components/News';


class NewsAndUpdates extends Component{
    render() {
        return (
            <div className = "wrapper">
                <section className='one'>
                    <Landing />
                </section>
                <section className='Newsitems'>
                    <Newsitems />
                </section>
                <section className='five'>
                    <ContactUs />
                </section>
            </div>
        );
    }
}


export default NewsAndUpdates;