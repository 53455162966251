import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import styles from '../src/css/Home.css'
import UniNav from './Navbar'
// import Option from './Option'
import '../src/css/option.css'
import { Container, Row, Col,Button } from 'react-bootstrap';
class Option extends Component {

    render(){
        return (
            <Container fluid className='main-container' style={{fontFamily:'Avenir',paddingBottom:'5%', marginTop:'15%'}}>
                <h1 style={{color:'white',textAlign:'center',fontSize:'min(4rem,8vw)',fontWeight:'bold'}}>We help 
                    <b><font color="#ffb703" style={{fontWeight:'bold'}}> disadvantaged <br></br> students </font></b> 
                enter university</h1>
                {/* <h3 style={{color:'#bbb',textAlign:'center',fontSize:'30px',letterSpacing:"0px",position:'relative',left:'-0.4%'}}>Helping disadvantaged students enter university</h3> */}
                <Row style={{paddingTop:'5%'}}>
                    <Col className='option' style={{display:'flex',justifyContent:"end",alignItems:'end'}}>
                    <a href='/#/Mentorship' style={{textDecoration: 'none'}}>
                        <div class='button1'> Apply To Universities </div>
                        {/* <h6 style={{fontSize: '25px'}}> Compete For Universities </h6> */}
                    </a>
                    </Col>
                    <Col style={{paddingTop:'0%',paddingLeft:'10%'}}>
                        <div className='option' style={{color:'white'}}>
                        {/* <h6 style={{fontSize: '25px'}}> Support Our Mission </h6> */}
                        <a href='/#/SupportUs'>
                        <div class='button2'> Support Our Mission </div>
                        </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Option;