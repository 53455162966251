import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import '../src/css/terms.css';

// class Terms extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {};
//     }

//     render() {
//         return (
//             <div className='terms' style={{ background: '#8ecae6' }}>
//                 <Container>
//                     <div className='apply-container'>
//                         <p style={{
//                             textAlign: 'center',
//                             fontSize: '6vw',  // Use viewport width to scale with screen size
//                             lineHeight: '1.2', // Adjust line height for better readability
//                             margin: '20px 0'   // Add some margin for spacing
//                         }}>
//                             UniArk Mentorship Programme: Apply to receive guidance on your university applications (2025-26)!
//                         </p>
//                         <p style={{ textAlign: 'center' }}>
//                             Are you interested in pursuing a master's degree at a UK, US, or European university for courses beginning in the 2025-26 academic year? Would you like to receive FREE 1:1 mentorship as you put together your application materials? Apply for a place on UniArk’s Mentorship Programme to get support on applications from graduates of world’s top universities.
//                         </p>
                        
//                         <p><b>Programme:</b> UniArk Mentorship Programme</p>
//                         <p><b>Duration:</b> October 2024 - February 2025 or beyond where necessary</p>
//                         <p><b>Places available:</b> competitive and limited.</p>
//                         <p><b>Location:</b> Online (Mentees can be based anywhere in the world and will be matched with a mentor who will connect via email, WhatsApp, Zoom, etc.)</p>
//                         <p><b>Deadline:</b> Monday 16 September by Midday UK Time.</p>
//                         <p><b>Contact:</b> admin@uniark.co.uk</p>
                        
//                         <p style={{ margin: '40px 0' }}></p>
                        
//                         <p>
//                             <b style={{ fontWeight: '900' }}>PLEASE NOTE:</b> UniArk Mentorship Programme is highly competitive, and we receive a large number of applications from exceptional students from a wide range of backgrounds. To ensure your application receives the attention it deserves, we kindly request that you take your time to fill out the form thoughtfully and thoroughly. This application is your chance to tell us about your academic history, interest, passion and vision for your future. It is vital that you provide every information that gives us insight into your eligibility for the UniArk mentorship programme. We prioritise students from persecuted/oppressed communities and others facing unique challenges
//                             <p style={{ margin: '40px 0' }}></p>
//                             <p style={{ color: 'red' }}> Please provide authentic, personal responses to these questions. AI-generated answers will not provide us with insights into your true self and may result in rejection </p>
//                         </p>

//                         <p style={{ textAlign: 'center', fontSize: '1.2em', fontWeight: 'bold', margin: '20px 0' }}>
//                             To apply, <a href="https://forms.gle/9noer2JMCUAuGz9M8" target="_blank" style={{ color: '#0056b3', textDecoration: 'none', fontWeight: 'bold' }}>click here</a> to access the application.
//                         </p>
//                     </div>
//                 </Container>
//             </div>
//         );
//     }
// }

// Terms.propTypes = {};

// export default Terms;

class Terms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className='terms' style={{background:'#8ecae6'}}>
                <Container>
                    <div className='apply-container'>
                        <p style={{textAlign:'center',fontSize:'2em'}}>
                        Welcome to the UniArk Mentorship Programme application page 
                        </p>
                        <p style={{textAlign:'center'}}>
                        We’re thrilled that you have chosen UniArk to support you in your academic journey.

                        </p>
                        <p>
                    <b style={{fontWeight:'900'}}>PLEASE NOTE:</b> The admission cycle is currently finished for this academic year and will reopen in august 2025 in line with university admissions timeline.

                        </p>
                    </div>
                </Container>
            </div>
        );
    }
}

Terms.propTypes = {};

export default Terms;
