import React from 'react';
import PropTypes from 'prop-types';
import '../src/css/cards.css'

class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const results=[];
        for (const pos of this.props.position){
            results.push(<h6 className='card-pos crd-txt'>{pos}</h6>)
        }

        return (
            <div className='card-container' style={{background:this.props.cardColor, color:this.props.cardText}}>
                <div className="card-img" style={{backgroundImage:`url(${this.props.src})`}}></div>
                <h4 className='card-title crd-txt' style={{color:this.props.cardTitle, fontWeight:'bold'}}>{this.props.fname} {this.props.lname}</h4>

                {results}
                <h6 className='card-uni crd-txt'>{this.props.uni}</h6>
                <h6 className='card-degree crd-txt' style={{paddingBottom:'1rem'}}>{this.props.degree}</h6>
            </div>
        );
    }
}

Card.propTypes = {};

export default Card;
