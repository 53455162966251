import React from 'react';
import PropTypes from 'prop-types';
import Card from './cards';
import '../src/css/scrollcards.css';

class ScrollCardsV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className='scrollcard-container' style={{background:'#8ecae6'}}>
                <h1 className='scrollcard-title'>{this.props.title}</h1>
                <div className='scrollcard-cards'>
                    {
                        this.props.division.map((member) => (
                            <Card fname={member.first_name} lname={member.last_name} src={member.img} position={member.position} uni={member.education[0].uni} degree={member.education[0].degree} cardColor={this.props.cardColor} cardText={this.props.cardText} cardTitle={this.props.cardTitle}/>
                        )
                        
                        )
                    }
                </div>
            </div>
        );
    }
}

ScrollCardsV2.propTypes = {};

export default ScrollCardsV2;
