import React from 'react';
import '../src/css/navbar2.css'
import { Container } from 'react-bootstrap';


class Navbar2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        function myFunction() {
            var x = document.getElementById("myTopnav");
            if (x.className === "nav-list2") {
                x.className += " responsive";
            } else {
                x.className = "nav-list2";
            }
        }
        return (
            <div className='full-navbar'>
                <Container className='nav-bar'>
                    <div className="nav-logo"> 
                        <img src={require('../src/img/logo.png')} height='50' alt='uni-ark-logo' className="nav-logo-img"/>
                        <div className="nav-logo-title" style={{fontSize:'25px',marginLeft:'1rem',color:this.props.fontColor}}>UniArk</div>
                    </div>
                    <div className="nav-list">
                        <li className="nav-list-item"><a href="/" className="nav-list-a" style={{color:this.props.fontColor}}>Home</a></li>
                        <li className="nav-list-item">
                            <div className="nav-list-dropdown">
                                <div className="nav-list-dropdown-title" style={{color:this.props.fontColor}}>About Us <i class="fa fa-caret-down"></i></div>
                                <div className='nav-list-dropdown-content' style={{background:this.props.backgrndColor, marginTop:'1rem',padding:'1%',borderRadius:'0 0 25px 25px'}}>
                                    <a href="/#/AboutUs" className="nav-list-dropdown-item"  style={{color:this.props.fontColor,paddingBlock:'5%'}}>What We Do</a><br />
                                    <a href="/#/Team" className="nav-list-dropdown-item"  style={{color:this.props.fontColor,paddingBlock:'5%'}}>Our Team</a>
                                </div>
                            </div>
                        </li>
                        <li className="nav-list-item"><a href="/#/News" className="nav-list-a"  style={{color:this.props.fontColor}}>News</a></li>
                        <li className="nav-list-item">
                            <div className="nav-list-dropdown">
                                <div className="nav-list-dropdown-title"  style={{color:this.props.fontColor}}>For Students <i class="fa fa-caret-down"></i></div>
                                <div className='nav-list-dropdown-content' style={{background:this.props.backgrndColor, marginTop:'1rem',padding:'1%',borderRadius:'0 0 25px 25px'}}>
                                    <a href="/#/Mentorship" className="nav-list-dropdown-item"  style={{color:this.props.fontColor,paddingBlock:'5%'}}>Our Programme</a><br />
                                    <a href="/#/Apply" className="nav-list-dropdown-item"  style={{color:this.props.fontColor,paddingBlock:'5%'}}>Apply Now</a><br />
                                    {/* <a href="/#/UWS" className="nav-list-dropdown-item"  style={{color:this.props.fontColor,paddingBlock:'5%'}}>Scholarship</a><br /> */}
                                    <a href="/#/FAQ" className="nav-list-dropdown-item"  style={{color:this.props.fontColor,paddingBlock:'5%'}}>FAQs</a>
                                </div>
                            </div>
                        </li>
                        <li className="nav-list-item"><a href="/#/SupportUs" className="nav-list-a"  style={{color:this.props.fontColor}}>Support Us</a></li>
                    </div>
                    <button className="hamburger-icon" onClick={myFunction} style={{color:this.props.fontColor, border:this.props.fontColor + ' 2px solid',background:'transparent'}}>&#9776;</button>
                </Container>


                <ul className="nav-list2" id='myTopnav'>
                    <li className="nav-list-item"><a href="/" className="nav-list-a">Home</a></li>
                    <li className="nav-list-item">
                        <div className="nav-list-dropdown">
                            <div className="nav-list-dropdown-title">About Us <i class="fa fa-caret-down"></i></div>
                            <div className='nav-list-dropdown-content'>
                                <div><a href="/#/AboutUs" className="nav-list-dropdown-item">What We Do</a></div>
                                <div><a href="/#/Team" className="nav-list-dropdown-item">Our Team</a></div>
                            </div>
                        </div>
                    </li>
                    <li className="nav-list-item"><a href="/#/News" className="nav-list-a">News</a></li>
                    <li className="nav-list-item">
                        <div className="nav-list-dropdown">
                            <div className="nav-list-dropdown-title">For Students <i class="fa fa-caret-down"></i></div>
                            <div className='nav-list-dropdown-content'>
                                <div><a href="/#/Mentorship" className="nav-list-dropdown-item">Our Programme</a></div>
                                <div><a href="/#/Apply" className="nav-list-dropdown-item">Apply Now</a></div>
                                {/* <div><a href="/#/UWS" className="nav-list-dropdown-item">Scholarship</a></div> */}
                                <div><a href="/#/FAQ" className="nav-list-dropdown-item">FAQs</a></div>
                            </div>
                        </div>
                    </li>
                    <li className="nav-list-item"><a href="/#/SupportUs" className="nav-list-a">Support Us</a></li>
                </ul>
            </div>
        );
    }
}

Navbar2.propTypes = {};

export default Navbar2;
