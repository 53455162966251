import React, { Component } from "react";
import { ReactDOM } from "react";
import {Col, Row, Container, Card} from "react-bootstrap";
import '../../Home_v2/src/css/testimony.css'
import '../src/css/vision.css'

class Vision extends Component{

    render(){
        return (
            <div style={{backgroundColor: "#8ecae6"}}>
                <Container className='vision-div'>
                <Card className='vision-card'>
                    <div className="vision-card-content">
                        <div className='left-card1'>&nbsp;</div>
                        <div className="vision-content">
                        <h1 className="vision-title" style={{color: 'white',fontWeight:'900'}}>Our Vision</h1>
                            <p style={{textAlign:'justify',fontSize:'20px', color:'white'}}>
                            Our vision is to enable students from disadvantaged backgrounds worldwide to gain admission to prestigious universities such as Oxford and Cambridge. We believe that these world-class institutions provide unparalleled opportunities for personal and professional growth, equipping individuals with the skills to become future leaders. By preparing these students for academic and personal success, we aim to empower entire disadvantaged and persecuted communities, fostering a transformative impact that extends far beyond the classroom.
                            </p>
                        </div>
                    </div>
                </Card>
                </Container>
            </div>
        )
    }
}

export default Vision;