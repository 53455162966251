import React from 'react';
import ReactDOM from 'react-dom/client';
import '../../Home_v2/src/css/services.css'
import { Container, Row, Col,Image,Card, } from 'react-bootstrap';
import Wave from '../../Home_v2/components/wave'
import CardItem from './card-item'
import '../../AboutUs/src/css/option.css'

class Option extends React.Component {
    render() {
        return (
            <div className=''>
                <Container fluid className='main-container-AU' style={{fontFamily:'Avenir',paddingBottom:'5%'}}>
                <h1 style={{color:'white',textAlign:'center',fontSize:'min(7rem,15vw)',letterSpacing:"0px",position:'relative',left:'-0.4%'}}>Our Programme</h1>
                </Container>
            </div>
        );
    }
}

export default Option;




