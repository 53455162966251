import React from "react";
import { ReactDOM } from "react";
import {Col, Row, Container} from "react-bootstrap";
import { VerticalTimeline,VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import '../src/css/timeline.css'

class TimeLine extends React.Component {
    render(){
        return (
            <div className='timeline-div' style={{backgroundColor: "#8ecae6"}}>
                <Container>
                            <h1 style={{textAlign:'center', color:'white', fontWeight:'900', fontSize: 'min(4rem,7vw)'}}>Our Theory of Change</h1>
                            {/* <center>
                                <hr size='5px' color='black' width="20%" />
                            </center> */}
                            <VerticalTimeline lineColor="#fff">
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#ffb703', color: '#000', borderRadius:'25px', boxShadow:'none'}}
                                    contentArrowStyle={{ borderRight: '7px solid  #ffb703' }}
                                    iconStyle={{ background: '#ffb703', color: '#000' }}
                                >
                                    <h3 className="vertical-timeline-element-title" style={{fontWeight:'bold'}}>Problem</h3>
                                    {/* <h4 className="vertical-timeline-element-subtitle">Founder</h4> */}
                                    <p style={{textAlign:'justify'}}>
                                    Bright students from discriminated & persecuted backgrounds (ethnic, religious, sexual orientation, etc.) especially from developing countries face barriers in accessing higher education opportunities and have low chance of development and employment.
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#9370DB', color: '#000', borderRadius:'25px', boxShadow:'none'}}
                                    contentArrowStyle={{ borderRight: '7px solid  #9370DB' }}
                                    iconStyle={{ background: '#9370DB', color: '#000' }}
                                >
                                    <h3 className="vertical-timeline-element-title" style={{color:'white', fontWeight:'bold'}}>Intervention</h3>
                                    <p style={{textAlign:'justify', color:'white'}}>
                                        <ul>
                                            <li>Admission Applications Support</li>
                                            <li>Admission Test Support</li>
                                            <li>Interview Support</li>
                                            <li>Financial Support</li>
                                        </ul>
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#fff', color: '#000', borderRadius:'25px', boxShadow:'none'}}
                                    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
                                    iconStyle={{ background: '#fff', color: '#000'}}
                                >
                                    <h3 className="vertical-timeline-element-title" style={{color:'#fb8500', fontWeight:'bold'}}>Outputs</h3>
                                    <p style={{textAlign:'justify'}}>
                                        <ul>
                                            <li>Increase the number of previously excluded students from disadvantaged and persecuted backgrounds in elite higher education institutions in the UK/US.</li>
                                            <li>More access to universities through fully funded scholarships for the most excluded groups.</li>
                                            <li>Influence funders and partners to tackle the exclusion of the most persecuted and disadvantaged groups in accessing higher education opportunities.</li>
                                        </ul>
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#ffb703', color: '#000', borderRadius:'25px', boxShadow:'none'}}
                                    contentArrowStyle={{ borderRight: '7px solid  #ffb703' }}
                                    iconStyle={{ background: '#ffb703', color: '#000' }}
                                >
                                    <h3 className="vertical-timeline-element-title" style={{fontWeight:'bold', color:'black'}}>Outcomes</h3>
                                    <p style={{textAlign:'justify', color:'black'}}>
                                        <ul>
                                            <li>Enable bright individuals to leave oppressive backgrounds and seek further education.</li>
                                            <li>More attention paid to the most excluded and vulnerable groups.</li>
                                            <li>Better employment and development opportunities, removing individuals from the cycle of discrimination and persecution.</li>
                                            <li>Empower individuals with the education and skills needed to bring positive change to their home communities and countries.</li>
                                        </ul>
                                    </p>
                                </VerticalTimelineElement>
                                
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#9370DB', color: '#000', borderRadius:'25px', boxShadow:'none'}}
                                    contentArrowStyle={{ borderRight: '7px solid  #9370DB' }}
                                    iconStyle={{ background: '#9370DB', color: '#000' }}
                                >
                                    <h3 className="vertical-timeline-element-title" style={{color:'white', fontWeight:'bold'}}>Impact</h3>
                                    {/* <h4 className="vertical-timeline-element-subtitle">BSc Computer Science</h4> */}
                                    <p style={{textAlign:'justify', color:'white'}}>
                                        <ul>
                                            <li>Increased participation of disadvantaged and persecuted communities in higher education.</li>
                                            <li>Improved prosperity and social mobility for individuals and their communities.</li>
                                            <li>A more inclusive and effective system with educational opportunities for all.</li>
                                        </ul>
                                    </p>
                                </VerticalTimelineElement>
                            </VerticalTimeline>
                </Container>
                {/* <center>
                <hr size='5px' color='black' width="20%" />
                </center> */}
            </div>
        )
    }
};

export default TimeLine;
