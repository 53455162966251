import React from 'react';
import ReactDOM from 'react-dom/client';
import '../src/css/services.css'
import { Container, Row, Col,Image,Card, } from 'react-bootstrap';
import Wave from './wave'
import CardItem from './card-item'

// const cardstyle={
//     width:'100%',
//     cursor:'pointer',
//     borderRadius: '25px',
//     overflow:'hidden'
// }

class Services extends React.Component {
    render() {
        return (
            <div className='services' style={{background: '#8ecae6'}}>
                <h1 style={{fontSize:'min(4rem,7vw)',color:'#fff',fontWeight:'900'}}>Services</h1>
                <Container style={{marginTop:'0%',paddingBottom:'0%'}}>
                    <Row >
                        <Col>
                            <CardItem title='Personal Statements' content='Multidraft feedback on personal statements and other admisssion essay requirements tailored to every individual' src={require('../src/img/s_v2.jpeg')} />
                        </Col>
                        <Col>
                            <CardItem title='Admission Tests' content='1:1 support for admissions tests such as the NSAA, PAT, ENGAA, etc. in order to improve test performance' src={require('../src/img/s2.jpg')} />
                        </Col>
                        <Col>
                            <CardItem title='Interview Practice' content='Technical interview preparation provided by tutors who have successfully experienced the interview processes' src={require('../src/img/s3.jpg')} />
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            <CardItem title='Scholarship Applications' content='International students who require funding to study abroad will be supported by mentors who have successfully secured scholarships' src={require('../src/img/s4.jpg')} />
                        </Col>
                        <Col>
                            <CardItem title='General Support' content='Students with extra admission requirements such as the IELTS test will have a mentor who can guide them through it' src={require('../src/img/s5.jpg')} />
                        </Col>
                        <Col>
                            <CardItem title="Beyond Admissions" content='With our network of students, we can support international students in their first year of university whilst they adjust to the UK system if required' src={require('../src/img/s6.jpg')} />
                        </Col>
                    </Row>
                </Container>
            {/* <Wave firstWaveColor="#eee" secondWaveColor="#ddd" thirdWaveColor="#FFF0E3" show="none" /> */}
            </div>
        );
    }
}

export default Services;