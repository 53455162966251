import React,{useState} from "react";
import {Col, Row, Container, Card,Button,Modal} from "react-bootstrap";
import '../src/css/documents.css'

function Application()  {
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);
    const cardstyle={
        width:'100%',
        borderRadius: '25px',
        border: '0px'
    }
    const imageStyle = {
        maxWidth: '100%', /* Ensure image doesn't exceed container width */
        height: 'auto', /* Maintain aspect ratio */
    };

    return (
        <div className='donate-div' style={{paddingTop:'5%', background:'#8ecae6'}}>
            
            {/* <h1 style={{fontWeight: 'bold',fontSize:'min(7vw,4rem)',color:'#fff', textAlign:'center'}}>Overview</h1> */}
            <Container>
                <Card style={cardstyle}>
                    <Card.Body>
                    {/* <img src={process.env.PUBLIC_URL + `/UWS/${'UWSmainImage.PNG'}`} alt="UWS image main" style={imageStyle} /> */}
                        <Card.Body>
                            <h3 style={{fontWeight:'bold', color:'#fb8500'}}>APPLICATION PROCESS</h3>
                            <ol className="numbered-list">
                                <li> <span className="underline">Applications open: </span>  <strong>Monday 22 April 2024 </strong>  </li>
                                <li> <span className="underline">Applications close: </span>  <strong> Midday Friday 24 May 2024 (UK Time) </strong> </li>
                                <li><span className="underline">Short-Listing: </span>  UniArk emails shortlisted applicants with further instructions (within one week of date when applications have closed; if an applicant does not receive an email within this timeframe, they should assume their application has not been successful; applicants should not email to further inquire about their application status; unfortunately, UniArk will not be able to provide any feedback)</li>
                                <li><span className="underline">Interviews: </span>  UniArk invites the shortlisted applicants to interview soon after the closing date.</li>
                                <li><span className="underline">Final Decision: </span> Final decision is made by UniArk and shortlisted applicants are emailed whether they were successful or not. </li>
                            </ol>
                        </Card.Body>
                    </Card.Body>
                </Card>
            </Container>
        </div>

    );
}

// render(<HowHelp />);

export default Application;