import React, { Component } from "react";
import { ReactDOM } from "react";
import {Col, Row, Container, Card} from "react-bootstrap";

import '../src/css/approach.css'

class Approach extends Component{

    render(){
        return (
            <div style={{backgroundColor: "#8ecae6"}}>
                <Container className="approach-div">
                <Card className='approach-card'>
                    <div className="approach-card-content">
                        <div className="approach-content" style={{padding:'2rem 2rem'}}>
                            <h1 className="approach-title" style={{color: '#fb8500',fontWeight:'900'}}>Our Approach</h1>
                            <p style={{textAlign:'justify',fontSize:'20px'}}>
                            While several organisations help disadvantaged students, they do not provide personalised support and mostly restrict their aid to home students. 
                            The admission experience and requirements of students are specific to their background and our diverse team enables a tailored approach to meet individual needs. 
                            Our experienced mentors have experience in successfully applying to world-class universities and securing scholarships.
                            </p>
                        </div>
                        <div className='right-card2'>&nbsp;</div>
                    </div>
                </Card>
                </Container>
            </div>
        )
    }
}

export default Approach;