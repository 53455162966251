import react, { Component } from 'react';
import { ReactDOM } from 'react';
import '../src/css/wave.css';

class Wave extends Component{
    render(){
        return (
            <div className='wave-div'>
                <svg class="editorial"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28"
                    preserveAspectRatio="none">
                    <defs>
                    <path id="gentle-wave"
                    d="M-160 44c30 0
                        58-18 88-18s
                        58 18 88 18
                        58-18 88-18
                        58 18 88 18
                        v44h-352z" />
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="50" y="0" fill={this.props.firstWaveColor} />
                        <use xlinkHref="#gentle-wave" x="50" y="3" fill={this.props.secondWaveColor} />
                        <use xlinkHref="#gentle-wave" x="50" y="6" fill={this.props.thirdWaveColor} />
                    </g>
                    </svg>
                    {/* <img src={require('../src/img/boat.png')} className='uni-ico' style={{display:this.props.show}} /> */}
            </div>

        )
    }
}

export default Wave;