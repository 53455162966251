import React from "react";
import { Container, Card } from "react-bootstrap";
import '../src/css/documents.css'; 

function Apply() {
    const cardstyle = {
        width: '100%',
        borderRadius: '25px',
        border: '10px'
    };

    const containerStyle = {
        padding: '20px', // Adjust the padding value as needed
    };

    // Define the URL and title
    const url = "https://uniark.org/#/UWS";
    const title = "UniArk Scholarship";

    return (
        <div className='donate-div' style={{ paddingTop: '5%', background: '#8ecae6' }}>
            <Container style={containerStyle}>
                <Card style={cardstyle}>
                    <Card.Body>
                        <h3 style={{ fontWeight: 'bold', color: '#fb8500' }}>How to Apply</h3>
                        <br />
                        <p>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSeyedMvPywyCt8q-O3WKz5fuzGVuhJhnbDjps469_yYWQOD4w/viewform">Click Here</a> to fill in our online Google form.
                        </p>
                        <br />
                        <p>
                            It is advised that you compose your essays separately and then copy and paste them into the form for submission. AI-generated essays will not be accepted and your application will be disqualified.
                        </p>
                        <br />
                        <p>
                            Deadline for submission is midday on 24 May 2024 UK time. Applications submitted after the deadline will not be considered.
                        </p>
                        <br />
                        <div>
                            <h5 style={{ fontWeight: 'bold' }}>Privacy & Data Protection Notice - UniArk Women's Schoalrship</h5>
                            <a href="./src/img/Privacy & Data Protection Notice _ UniArk Women's Schoalrship.pdf" download>
                                Download PDF
                            </a>
                        </div>
                        <br />
                        <p>
                            Please contact us at <a href="mailto:admin@uniark.co.uk">admin@uniark.co.uk</a> for queries related to the scholarship.
                        </p>
                        <div>
                            <h5 style={{ fontWeight: 'bold' }}>Share on:</h5>
                            <a href={`https://twitter.com/share?url=${url}&text=${title}`} target="_blank">
                                <i className="fa fa-twitter social-media-icons" style={{paddingRight: "20px", fontSize: "40px"}}></i> 
                            </a> 
                            <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${title}`} target="_blank">
                                <i className="fa fa-facebook social-media-icons" style={{paddingRight: "20px", fontSize: "40px"}}></i>
                            </a>
                            <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${title}`} target="_blank">
                                <i className="fa fa-linkedin social-media-icons" style={{paddingRight: "20px", fontSize: "40px"}}></i>
                            </a>
                            <a href={`https://www.instagram.com`} >
                                <i className="fa fa-instagram social-media-icons" style={{paddingRight: "20px", fontSize: "40px"}}></i>
                            </a>
                        </div>
                        <br />
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}

export default Apply;

