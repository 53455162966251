import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import { Row, Col, Container, Card } from 'react-bootstrap';
import YoutubeEmbed from './YouTubeEmbed';
import '../src/css/currevent.css'


class CurrEvent extends Component {
    render() {
        return (
            <div style={{background:'#8ecae6'}}>

            
                <Container className='currevent-container'>
                    <Row style={{ paddingTop: '5%' }}>
                        <Col>
                            <h1 className='currevent-section-title' >Invest In Her Future</h1>
                        </Col>
                    </Row>

                    <center>
                    <Card className='currevent-card'>
                            <Card.Subtitle><h4 className='currevent-card-title'>UniArk Women's Scholarship</h4></Card.Subtitle><br />
                            <YoutubeEmbed />
                            <Card.Text>
                            Take a stand for educational justice by donating to the education of disadvantaged women.
                            Education is a powerful tool that can change lives and shape futures. 
                            Access to education can mean the difference between a life of oppression and a future filled with limitless possibilities. 
                            By donating to UniArk's Women's Scholarship, you can help give women the opportunity to receive higher education and build a brighter future for herself and her community.
                            <br /><br /> 
                            <a href='https://gofund.me/d1f3e6c7' target="_blank">Read more about the campaign.</a>
                            </Card.Text>
                    </Card>
                    </center>
                </Container>
            </div>
        );
    }
}

export default CurrEvent;