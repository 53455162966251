import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';


class AcItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Accordion.Item className='accordion-item' eventKey={this.props.ekey}>
                <Accordion.Header> <h3 className="accordion-title">{this.props.title}</h3> </Accordion.Header>
                <Accordion.Body className='accordion-body'>
                    {this.props.text}
                </Accordion.Body>
            </Accordion.Item>
        );
    }
}

AcItem.propTypes = {};

export default AcItem;
