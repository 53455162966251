import logo from './logo.svg';
import React from 'react';
import './App.css';
import {
  BrowserRouter,
  Route,
  Routes,
  HashRouter,
  Switch,
} from "react-router-dom";
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import Mentorship from './pages/Mentorship/Mentorship'
import Support from './pages/Support/Support';
import Home_v2 from './pages/Home_v2/Home_v2';
import Policy from './pages/Policy/policy';
import Team from './pages/Team/team';
import FAQ from './pages/FAQ/faq';
import Apply from './pages/Apply/apply';
import ApplyMentor from './pages/ApplyMentor/applyMentor';
import NewsAndUpdates from './pages/News/NewsAndUpdates';
import UWS from './pages/UWS/UWS'


function App() {
  return (
    // <BrowserRouter>
      <HashRouter basename='/'>
        <Routes>
          <Route exact path='/v2' element={<Home />}></Route>
          <Route exact path='/AboutUs' element={<AboutUs />}></Route>
          <Route exact path='/SupportUs' element={<Support />}></Route>
          <Route exact path='/' element={<Home_v2 />}></Route>
          <Route exact path='/Policy' element={<Policy />}></Route>
          <Route exact path='/Mentorship' element={<Mentorship />}></Route>
          <Route exact path='/Team' element={<Team />}></Route>
          <Route exact path='/FAQ' element={<FAQ />}></Route>
          <Route exact path='/Apply' element={<Apply />}></Route>
          <Route exact path='/ApplyMentor' element={<ApplyMentor />}></Route>
          <Route exact path='/News' element={<NewsAndUpdates />}></Route>
          <Route exact path='/UWS' element={<UWS />}></Route>
        </Routes>
      </HashRouter>
    // </BrowserRouter>
  );
}

export default App;
