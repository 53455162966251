
import React from 'react';
import { Container, Row, Col, Image, Card } from 'react-bootstrap';
import Newsitem from './newsitem';
import updateItems from '../NewsUpdates.json';



class Newsitems extends React.Component {
  render() {
    return (
      <div className='newsitems' style={{background:'#8ecae6', paddingBottom:'5%'}}>
        {updateItems.updateItems.map(item => (
          <Newsitem key={item.id} title={item.title} imageSrc={item.HeadImageLocation} date={item.date}
          NewsContent={item.content} 
          imageSrc2={item.ContentImageLocation}/>
        ))}
      </div>
    );
  }
}

export default Newsitems;