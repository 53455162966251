import React from "react";  
import '../src/css/landing.css';
import Navbar2 from "../../Home_v2/components/navbar2";
import Option from "./Option";
import Wave from '../../Home_v2/components/wave'

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state={
        status:"",
        colorStatus:'white',
        logoStatus:'0',
        navcolorStatus:'transparent',
    };
  }

  componentDidMount() {
      this.listener = document.addEventListener("scroll", e => {
          var scrolled = document.scrollingElement.scrollTop;
          if (scrolled >= 120) {
            if (this.state.status !== "white") this.setState({ status: "white" });
            if (this.state.colorStatus !== "black") this.setState({ colorStatus: "black" });
            if (this.state.logoStatus!=='1') this.setState({logoStatus:'1'});
            if (this.state.navcolorStatus !== "white") this.setState({ navcolorStatus: "white" });
          } else {
            if (this.state.status !== "") this.setState({ status: "" });
            if (this.state.colorStatus !== "white") this.setState({ colorStatus: "white" });
            if (this.state.logoStatus!=='0') this.setState({logoStatus:'0'});
            if (this.state.navcolorStatus !== "transparent") this.setState({ navcolorStatus: "transparent" });
          }
      });
  };


  render(){
      const mystyle = {
          width:'100%',
          backgroundColor: this.state.status,
          position:'fixed',
          zIndex:'1000',
          };
      return (
          <div className='p-landing' style={{width:'100%'}}>
              <div style={mystyle}>
              <Navbar2 fontColor={this.state.colorStatus} backgrndColor={this.state.status} />
              </div>  
              <br />
              <Option />
              <Wave firstWaveColor='#d2eaf5' secondWaveColor='#638da1' thirdWaveColor="#8ecae6" show="1" />
          </div>
      );
  }
}

export default Landing;