import React,{useState} from "react";
import {Col, Row, Container, Card,Button,Modal} from "react-bootstrap";
import '../src/css/categories.css'

function Categories()  {
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);
    const cardstyle={
        width:'100%',
        borderRadius: '25px',
        border: '0px'
    }

    return (
        <div className='donate-div' style={{paddingTop:'5%', background:'#8ecae6'}}>
            
            <h1 style={{fontWeight: 'bold',fontSize:'min(7vw,4rem)',color:'#fff', textAlign:'center'}}>Overview</h1>
            <Container>
                <Card style={cardstyle}>
                    <Card.Body>
                        <Card.Body>
                        <h3 style={{fontWeight:'bold', color:'#fb8500'}}>What we provide</h3>
                        We provide <b>FREE</b> 1:1 mentoring to help aspiring students from persecuted or disadvantaged groups anywhere around the world apply to UK/US/European universities.
                        We will advise and support you through all stages of your application process including:
                        <ul>
                            <li>Selection of courses and universities to apply for based on your academic interests and career aspirations</li>
                            <li>Feedback and revision of personal statements and essays</li>
                            <li>Preparation for enterance tests</li>
                            <li>Conducting mock university interviews</li>
                            <li>Helping you find and apply to scholarships/funding opportunities</li>
                        </ul>
                        </Card.Body>
                        <Card.Body>
                        <h3 style={{fontWeight:'bold', color:'#fb8500'}}>What we expect</h3>
                        <ul>
                            <li>Be punctual and engaged in communications, including informing your mentor if you cannot attend a meeting or application deadline</li>
                            <li>Not completely rely on your mentor to draft your application and reflect and respond to your mentor’s feedback</li>
                            <li>Take an active role in searching for funding opportunities and identifying your eligibility for them</li>
                            <li>Provide testimonial and feedback if requested at the end of your programme (we will be very careful with privacy concerns)</li>
                            <li>Report immediately about any mentor misbehaviour, or noncompliance with the Child Protection and Safeguarding policies of UniArk, to a senior member of the team</li>
                            <li>Immediately contact the UniArk team if the match is not appropriate or if the mentor is not responsive for an unreasonable length of time</li>
                        </ul>
                        </Card.Body>
                        <div className="prog-button-div">
                            <Button onClick={handleShow4} style={{background:'#050A30',color:'white', borderRadius:'25px', border:'0px',marginInlineEnd:'1%', fontSize: 'min(25px,4vw)', fontWeight:'bold'}}>Check my eligiblity</Button>
                            <a  href='/#/Apply'>
                                <Button style={{background:'#fb8500',color:'white', borderRadius:'25px', border:'0px', fontSize: 'min(25px,4vw)', fontWeight:'bold'}}>Apply Now</Button>
                            </a>
                        </div>
                    </Card.Body>
                </Card>
            </Container>

            <Modal show={show4} onHide={handleClose4} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton closeVariant="white">
                <Modal.Title style={{textAlign:'center'}}><h2 style={{color:'white'}}> &nbsp; Eligibility Criteria</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{paddingTop:'3%',paddingBottom:'3%'}}>
                        <Col style={{alignItems:'center',paddingRight:'5%', paddingLeft:'5%',textAlign:'justify',fontSize:'18px'}}>
                        To join our mentoring programme, you must meet all the following criteria:
                        <ul>
                            <li>Be interested in higher education studies</li>
                            <li>Be highly motivated and ambitious to make a successful application to a leading global university</li>
                            <li>Be self-driven and proactive in making progress in your application</li>
                            <li>Be from a persecuted community (e.g. ethnic, religious, gender, sexual orientation) or socioeconomically disadvantaged group.</li>
                            <li>Be proficient/fluent in the English language (this is a requirement for all UK/US/European universities). Please note you will eventually need to provide an English exam certificate to each university you make an application to. We understand that taking such exams may have its own barriers (e.g. economical) so please get in touch if you need help with this.</li>
                        </ul> 
                        </Col>
                    </Row>
                </Modal.Body>

            </Modal>

        </div>

    );
}

// render(<HowHelp />);

export default Categories;