

import React from 'react';
import '../src/css/uwsimage.css';

const UWSImage = () => {
  const images = [
    'UWSmainImage.PNG'
  ];

  return (
    <div className="UWSImages" style={{ background:'#8ecae6', paddingTop:'5%' }}>
      {/* <h1 style={{ fontSize:'min(4rem,7vw)', color:'#fff', fontWeight:'900' }}>UniArk's Women Scholarship For Afghanistan</h1> */}
      <div className="uws-images-container">
        {images.map((image, index) => (
          <img
            key={index}
            src={process.env.PUBLIC_URL + `/UWS/${image}`}
            alt={`uws image ${index}`}
            className="uws-imag"
          />
        ))}
      </div>
    </div>
  );
};

export default UWSImage;
