import React,{useState} from "react";
import {Col, Row, Container, Card,Button,Modal} from "react-bootstrap";
import '../src/css/eligibility.css'

function Eligibilities()  {
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);
    const cardstyle={
        width:'100%',
        borderRadius: '25px',
        border: '0px'
    }
    const imageStyle = {
        maxWidth: '100%', /* Ensure image doesn't exceed container width */
        height: 'auto', /* Maintain aspect ratio */
    };

    return (
        <div className='donate-div' style={{paddingTop:'5%', background:'#8ecae6'}}>
            
            {/* <h1 style={{fontWeight: 'bold',fontSize:'min(7vw,4rem)',color:'#fff', textAlign:'center'}}>Overview</h1> */}
            <Container>
                <Card style={cardstyle}>
                    <Card.Body>
                    {/* <img src={process.env.PUBLIC_URL + `/UWS/${'UWSmainImage.PNG'}`} alt="UWS image main" style={imageStyle} /> */}
                        <Card.Body>
                        <h3 style={{fontWeight:'bold', color:'#fb8500'}}>Overview</h3>
                         <p>UniArk is delighted to offer a fully-funded scholarship (<strong>£ 35,000</strong>), awarded on the basis of academic merit and potential, for a woman from Afghanistan to pursue higher education in any of the following countries: United Kingdom, United States, Australia, New Zealand, or within the European Union. </p>
                         
                         <p> It is intended that the scholarship will benefit a female student from Afghanistan from an economically and socially disadvantaged background. Priority will be given to students who are at risk of persecution in the country. </p>
                          
                         <p>In light of the dangers and threats faced by women and ethnic minority groups in Afghanistan, this scholarship aims to offer the recipient the opportunity for an educational experience free from the threat of violence. </p>
                          
                         <p> We’re grateful for the generosity of philanthropists in the UK and members of the public for their donations which has made this scholarship possible. </p>
                        </Card.Body>
                        <Card.Body>
                            <h3 style={{fontWeight:'bold', color:'#fb8500'}}>ELIGIBILITY CRITERIA</h3>
                            <ol className="numbered-list">
                                <li><span className = "underline"> Financial Support Required:</span> the applicant must <strong>not</strong> be in need of more than <strong>£35k at the time of application </strong> to have all the financial requirements for enrollment, as defined by their university, satisfied. (this does not include visa and travel costs)  </li>
                                <li><span className="underline">Demographics:</span>  the applicant must be of female gender (as written on their passport or other identification document) and have usual residence in Afghanistan <strong>at the time of application;</strong> applicants living outside of Afghanistan will not be considered.</li>
                                <li><span className="underline">Degree to Be Pursued:</span>  the applicant must be seeking financial support for a master’s degree or an undergraduate degree; however, applicants seeking a second undergraduate degree or a second masters degree will not be considered. </li>
                                <li><span className="underline">Enrollment Status:</span> the applicant must have an offer to start a course at their target university <strong> at the time of application </strong> </li>
                                <li><span className="underline">Geographical Requirement:</span> the university whose course the applicant wishes to enrol must be from one of the following countries: United Kingdom, United States, Australia, New Zealand, EU. </li>
                            </ol>
                        </Card.Body>
                    </Card.Body>
                </Card>
            </Container>
        </div>

    );
}

// render(<HowHelp />);

export default Eligibilities;