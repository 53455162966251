import React from "react";
import { ReactDOM } from "react";
import {Col, Row, Container} from "react-bootstrap";
// import UniNav from "../Home/components/Navbar";
import ParticlesBg from 'particles-bg'
import './src/css/supportus.css'
import SULanding from "./components/landing";
import ContactUs from "../Home_v2/components/contactus";
import UWSImage from './components/UWSImage';
import Eligibilities from "./components/Eligibility";
import Documents from "./components/Documents";
import Application from "./components/ApplicationProcess";
import Apply from "./components/Apply";

class Support extends React.Component {
    render(){
        return (
            <div>
                <section className="one">
                        <SULanding />
                </section>
                <section className="UWSImageSection">
                <UWSImage />
                </section>
                <section className="eligibilities list">
                    <Eligibilities></Eligibilities>
                </section>
                <section className="documents required">
                    <Documents />
                </section>
                <section className="application process">
                    <Application />
                </section>
                <section className="apply here">
                    <Apply />
                </section>
                <section className='three'>
                    <ContactUs />
                </section>
            </div>
        )
    }
};

export default Support;