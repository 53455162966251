import React, { Component } from "react";
import { ReactDOM } from "react";
import {Col, Row, Container, Card} from "react-bootstrap";
import '../src/css/mission.css'

class Mission extends Component{

    render(){
        return (
            <div style={{backgroundColor:'#8ecae6'}}>
                <Container className='mission-div' >
                <Card className='mission-card'>
                    <div className="mission-card-content">
                        <div className='mission-content' style={{padding:'2rem 2rem',width:'100%'}}>
                            <h1 className="mission-title" style={{color: '#fb8500',fontWeight:'900'}}>Our Mission</h1>
                            <p style={{textAlign:'justify',fontSize:'20px'}}>
                            At UniArk, we help disadvantaged students gain entry into universities in the US and UK, focussing on individuals from developing nations and persecuted backgrounds.
                            We provide 1:1 mentoring to guide students through every stage of the university application process. 
                            Our experienced mentors provide support with university selection, personal statements, admissions tests, interview practice and scholarship applications.
                            </p>
                        </div>
                        <div className='right-card1'>&nbsp;</div>
                    </div>
                    </Card>
                </Container>
            </div>
        )
    }
}

export default Mission;