import React,{useState} from "react";
import {Col, Row, Container, Modal} from "react-bootstrap";
import '../src/css/newsitem.css'



function Newsitem(props) {
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [setHovered]=useState(false);

    const handleHoverInScale = () => setHovered(true);
    const handleHoverOutScale = () => setHovered(false);

    const cardstyle={
        
    }
    return (
        <div className='news-items' style={{paddingTop:'2%'}}>
            <Container onClick={handleShow1}>
                <div style={cardstyle} className="news-item-container" onMouseEnter={handleHoverInScale} onMouseLeave={handleHoverOutScale}>
                    <div className="news-content">
                        <h4 style={{fontWeight:'bold',fontSize:'min(5vw,2rem)',color:'#000'}}>{props.title}</h4>
                        <p style={{fontSize:'max(1.5vw,0.5rem)',color:'#fb8500'}}>{props.date}</p>
                    </div>
                    <div className="news-img" style={{ background:'url('+props.imageSrc+')'}}>
                    &nbsp;
                    </div>
                </div>
            </Container>

            <Modal show={show1} onHide={handleClose1} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton closeVariant="white">
                <Modal.Title style={{textAlign:'center'}}></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Row style={{paddingTop:'0%',paddingBottom:'3%'}}>  
                    <h4 style={{fontWeight:'bold',fontSize:'2vw',color:'#000'}}>{props.title}</h4>
                    <p style={{fontWeight:'normal', fontSize:'1.5vw',color:'#000'}}>{props.date}</p>
                    <img src={props.imageSrc} style={{width: '100%', aspectRatio:'16/9', objectFit:'cover', position:'centre'}} alt="" />                           
                </Row>
                <Row style={{paddingTop:'0%',paddingBottom:'3%'}}>   
                    <Col style={{display:'flex',alignItems:'center',paddingRight:'7%',textAlign:'justify',fontSize:'18px'}}>
                    <div dangerouslySetInnerHTML={{__html:props.NewsContent}}></div>
                    </Col>
                </Row>
                <Row style={{paddingTop:'0%',paddingBottom:'3%'}}>   
                <img src={props.imageSrc2} style={{width: '100%'}} alt="" />
                </Row>
                </Modal.Body>

            </Modal>

        </div>

    );
}

// render(<HowHelp />);


export default Newsitem;

