import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Container, Col } from 'react-bootstrap';
import '../src/css/terms.css';

class Terms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className='terms' style={{background:'#8ecae6'}}>
                <Container>
                    <div className='apply-container'>
                        <p style={{textAlign:'center',fontSize:'3em'}}>
                            Welcome to the UniArk Future Mentors! 
                        </p>
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfakZ7FBEG4FiNYCoUHD4f5DpgB7RWhXW9suin6euh56y1OfA/viewform?embedded=true" width="100%" height="1000px" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    </div>
                </Container>
            </div>
        );
    }
}

Terms.propTypes = {};

export default Terms;
