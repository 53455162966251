import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Container } from 'react-bootstrap';
import AcItem from './accordion';
import '../src/css/terms.css';

class Terms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className='terms' style={{background:'#8ecae6'}}>
                <Container>
                    <Accordion>
                        <Accordion.Item className='accordion-item' eventKey='0'>
                            <Accordion.Header> <h3 className="accordion-title"><b style={{color:'#fb8500'}}>1.</b> Disclaimer</h3> </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                            This page outlines all our policies and safeguarding rules and is subject to regular updates and revisions. We advise that you check this page periodically.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='accordion-item' eventKey='1'>
                            <Accordion.Header> <h3 className="accordion-title"><b style={{color:'#fb8500'}}>2.</b> Data Protection & Privacy</h3> </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                                UniArk is committed to safeguarding your privacy online. This statement explains how we will collect, use and disclose information. <br /> <br />
                                We understand the importance of data privacy and UniArk will take all the reasonable measures to ensure your information is secure. <br /> <br />
                                How we use, save and disclose your information varies depending on how you interact with UniArk. When you make an application for mentorship, you give us consent to share this information with our admission team to match and assign you a mentor. There may be occasions where we share your information for funding opportunities, lobbying and appeals (e.g with potential funders/ sponsors/ universities/ charities) however, your consent and permission will be requested beforehand and you will be informed of the process.<br /> <br />
                                <h4 className='ac-subheading'>WHAT INFORMATION WE COLLECT AND FOR WHAT PURPOSES:</h4>
                                <ul>
                                    <li>Name & contact address: to keep in touch with you.</li>
                                    <li>Educational background: to process your application, assign and match mentor and mentees</li>
                                    <li>Economic/social background: to check your eligibility for our mentoring programme and to offer and sign post you to the relevant opportunities and support.</li>
                                </ul>
                                <h4 className='ac-subheading'>WHERE WE COLLECT INFORMATION FROM: </h4>
                                <ul>
                                    <li>Directly from you or through your registration form</li>
                                    <li>Google collects information in the form of cookies when you visit our website. This information is collected automatically. It does not contain any personal or sensitive information, so browsing is anonymous.</li>
                                </ul>
                                <h4 className='ac-subheading'>WHO HAS ACCESS TO YOUR INFORMATION:</h4>
                                <ul>
                                    <li>Information provided on registration forms is accessible to co-founders, directors, your mentor and administrative staff.</li>
                                    <li>We never sell your personal details to third parties for the purpose of marketing. Whenever we share or transfer your personal information, we comply with the standards set by the GDPR and this privacy statement at a minimum.</li>
                                </ul>
                                <h4 className='ac-subheading'>HOW LONG WE KEEP YOUR INFORMATION FOR:</h4>
                                <ul>
                                    <li>How long we keep your information for varies and depends on the purpose. Information about mentees/volunteers will be kept in our files during their participation in our mentoring programme, after which the data is deleted unless they wish to stay on our mailing list to keep in touch with us.</li>
                                </ul>
                                <h4 className='ac-subheading'>HOW YOUR INFORMATION IS SECURE:</h4>
                                <ul>
                                    <li>The security of your information is highly important to us. UniArk will take reasonable measures to protect your information as required by the law, in line with industry standards and in accordance with the policies of UniArk. All sensitive information will be protected strictly. If you have any concerns, please contact us immediately.</li>
                                </ul>
                                <h4 className='ac-subheading'>YOUR RIGHTS:</h4>
                                <ul>
                                    <li>You have the right to:</li>
                                    <li>Ask for a copy of the personal data that we hold about you</li>
                                    <li>Request that we delete your personal data where we no longer have any legal reasons to retain it</li>
                                    <li>Change how you hear from us such as opting out of our communications</li>
                                </ul>
                                For all the above, please get in touch with us: admin@uniark.co.uk
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='accordion-item' eventKey='2'>
                            <Accordion.Header> <h3 className="accordion-title"><b style={{color:'#fb8500'}}>3.</b> Safeguarding & Child Protection</h3> </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                                <ul>
                                    <li>At UniArk, we are committed to ensuring that your experience with us is free from any form of exploitation and/or abuse. We will take the necessary steps to ensure that you feel safe, secure and respected at our organisation. </li>
                                    <li>To enforce our Child Protection and Safeguarding Policy, those mentors working with mentees under the age of 18 and vulnerable adults will be DBS checked. There are a range of preventative measures and systems in place to prevent any form of abuse. There are also clear procedures for disciplinary action in the event this policy is breached. Should you have any concerns, please contact us immediately on admin@uniarkco.uk </li>
                                </ul>
                                To discuss any concerns you have, please contact our trustees;<br />
                                Najib Sharifi - nsharifi650@gmail.com <br />
                                Shukria Rezaei - shukriarezaei@gmail.com
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='accordion-item' eventKey='3'>
                            <Accordion.Header> <h3 className="accordion-title"><b style={{color:'#fb8500'}}>4.</b> Volunteer code of conduct</h3> </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                            The Volunteer Code of Conduct sets out the standards and expectations of volunteers in terms of professional ethics, integrity, acting as a representative and safeguarding, which support our vision, mission and values. <br /> <br />
                            <a href='files/MentorCodeofConduct.pdf'>Click Here</a> to view the mentor code of conduct. 
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='accordion-item' eventKey='4'>
                            <Accordion.Header> <h3 className="accordion-title"><b style={{color:'#fb8500'}}>5.</b> Financial Transparency</h3> </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                                UniArk is a UK registered charity. All donations received by UniArk are completely reinvested into the initiative, to support our mission. Donations made for specific students and projects will be used for the purpose it was donated, unless no longer needed, in which case it will be used to support other students. <br /> <br />

                                We are seeking funding to support our students to fund their degrees at global leading universities. Specifically, this entails:
                                <ul>
                                    <li>Supporting aspiring students with grants, bursaries and scholarships</li>
                                    <li>Running our Breakout rooms for our online mentoring, which give our Mentors and Mentees uninterrupted meeting times.</li>
                                    <li>Supporting the running of our communication channels such as social media, graphic design softwares, virtual video meeting platforms such as zoom accounts which offer greater functionality</li>
                                    <li>Covering the cost of running our domain and hosting services. </li>
                                </ul>   
                                As a registered charity, UniArk will provide a full and transparent report of its finances to the Charity Commission, annually. This information will also be provided on the organisation's annual report published and made available on its website. 
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='accordion-item' eventKey='5'>
                            <Accordion.Header> <h3 className="accordion-title"><b style={{color:'#fb8500'}}>6.</b> Cookies Policy</h3> </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                            We do not collect any information using cookies on our website.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Container>
                
            </div>
        );
    }
}

Terms.propTypes = {};

export default Terms;
