import React from "react";
import { ReactDOM } from "react";
import {Col, Row, Container} from "react-bootstrap";
// import UniNav from "../Home/components/Navbar";
import ParticlesBg from 'particles-bg'
import './src/css/supportus.css'
import SULanding from "./components/landing";
import ContactUs from "../Home_v2/components/contactus";
import WhyDonate from "./components/whydonate";
import HowHelp from "./components/howhelp";

class Support extends React.Component {
    render(){
        return (
            <div>
                <section className="one">
                        <SULanding />
                </section>
                <section className='two'>
                    <WhyDonate />
                </section>
                <section className='four'>
                    <HowHelp />
                </section>
                {/* <section className='five'>
                    <Approach />
                </section>
                <section className='three'>
                    <TimeLine />
                </section> */}
                <section className='three'>
                    <ContactUs />
                </section>
            </div>
        )
    }
};

export default Support;