import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import Landing from './components/landing'
import UniArkMission from './components/missionVideo'
import CurrEvent from './components/currevent'
import Services from './components/services'
import Testimony from './components/testimony'
import ContactUs from './components/contactus';

//import ParticlesBg from 'particles-bg';
import UniversityLogos from './components/UniversityLogos';

class Home_v2 extends Component{
    render() {
        return (
            <div className = "wrapper">
                <section className='one'>
                    <Landing />
                </section>
                <section className='oneb'>
                    <UniArkMission />
                </section>
                <section className="university-logos-section">
                <UniversityLogos />
                </section>
                <section className='two'>
                    <Testimony />
                </section>
                {/* <section className='three'>
                    <CurrEvent />
                </section> */}
                <section className='four'>
                    <Services />
                </section>
                <section className='five'>
                    <ContactUs />
                </section>
            </div>
        );
    }
}
export default Home_v2;